export const environment = {
  name: 'staging',
  production: false,
  protocol: 'https://',
  baseAddress: 'api.staging.monek.com',
  odinBaseAddress: 'api.staging.monek.com/odin',
  clientRoot: 'https://staging-portal.monek.com/',
  apiRoot: 'api.staging.monek.com/odin/',
  releaseVersion: 'SHA-af009fad7e17f49e10c81a6a04ea3a4772cd47fa',

  // cognito
  userPoolId: 'eu-west-2_FBa9FdzbI',
  appClientId: '49icjbbdjfum6lm89htiiec5ql',
  hostedLoginUrl: 'https://staging-authorise.monek.com',
  authCallbackUrl:
    'https://staging-portal.monek.com/authentication/auth-callback',

  congitoResource: 'https://staging-portal.monek.com/dashboard',
  cognitoAudience: 'https://api.staging.monek.com/odin/transactions',
  sessionInactivityLimit: 900000,

  sentryKey:
    'https://77cf1ecf142e4954b99f121e84bca18d@o4505487026159616.ingest.sentry.io/4505487030157312',
  sentrySampleRate: 1.0,
  sentryMaskData: true,

  featureToggles: {
    fuzzySearch: true,
    'txn-recharge': true,
    'vt-page': true,
    'pebl-page': true,
    'pci-widget': false,
    'dashboard-metrics': true,
    'user-mfa-management': false,
  },

  brandings: [
    {
      name: 'MONEK BRANDING',
      logoUrl: '/assets/images/logo.png',
      brandName: 'MONEK',
      hasTextLogo: true,
      textLogoClass: 'monek-font',
      fixedTheme: false, // can change theming?
      themeClass: '', // theme class if fixedTheme is true
      logoWidth: 'auto',
      logoHeight: '20px',
      availableThemes: [
        { value: 'light', view: 'Light Mode' },
        { value: 'dark', view: 'Dark Mode' },
      ],
    },
  ],
  selectedBrandName: 'MONEK BRANDING',
};
